import { CommonModule as AngularCommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { BusinessModule } from 'app/modules/common/business/business.module';
import { MaterialModule } from 'app/modules/common/material-module';
import { FrameworkModule } from '../../common/framework/framework.module';
import { HomeComponent } from './components/home/home.component';

@NgModule({
  declarations: [HomeComponent],
  imports: [FrameworkModule, AngularCommonModule, MaterialModule, BusinessModule],
  exports: [HomeComponent],
})
export class HomeModule {}
