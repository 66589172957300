import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { jwtDecode } from 'jwt-decode';
import { DateTime } from 'luxon';
import { Observable, catchError, mergeMap, throwError } from 'rxjs';
import { environment } from '../../../../../environments/environment';
import { AuthService } from '../../../common/business/auth/services/auth.service';

/**
 * Intercepts API Http requests and refreshes the application token if expired.
 */
@Injectable()
export class JwtExpiredInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  /**
   * Verifies if the token is expired.
   */
  private isExpiredToken(token: string): boolean {
    return DateTime.now().toSeconds() > jwtDecode(token).exp!;
  }

  /**
   * Verifies if the provided url is to the backend and should include the token.
   *
   * @param url the request url
   * @returns true, if the request should include the token
   */
  private isApiRequest(url: string): boolean {
    return url.startsWith(environment.apiUrl) && !url.startsWith(`${environment.apiUrl}/auth/aad`);
  }

  /**
   * Verifies if the application token is expired, if so refresh it before forwarding the request.
   *
   * @param request the request object
   * @param next callback to proceed with the request
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    const accessToken = this.authService.getAccessToken();

    if (this.isApiRequest(request.url) && accessToken && this.isExpiredToken(accessToken)) {
      return this.authService.refreshLogin().pipe(
        catchError((err) => {
          this.authService.logout({
            queryParams: { returnUrl: this.router.routerState.snapshot.url },
          });

          return throwError(() => err);
        }),
        mergeMap(() => {
          return next.handle(request);
        }),
      );
    } else {
      return next.handle(request);
    }
  }
}
