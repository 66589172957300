import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { Observable, throwError } from 'rxjs';
import { catchError } from 'rxjs/operators';
import { AuthService } from '../../../common/business/auth/services/auth.service';

/**
 * Intercepts API Http responses and checks for authentication errors.
 */
@Injectable()
export class UnauthorizedInterceptor implements HttpInterceptor {
  constructor(
    private authService: AuthService,
    private router: Router,
  ) {}

  /**
   * Checks for a Unauthorized (HTTP 401) error and redirects to the login page.
   * @param request
   * @param next
   */
  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return next.handle(request).pipe(
      catchError((err) => {
        if (err.status === 401) {
          this.authService.logout({
            queryParams: { returnUrl: this.router.routerState.snapshot.url },
          });
        }

        return throwError(() => err);
      }),
    );
  }
}
