import { Injectable } from '@angular/core';
import { SwUpdate } from '@angular/service-worker';
import { TrackerService } from './tracker.service';

/**
 * Tracks service worker update events.
 * Based on snippet from https://angular.io/guide/service-worker-communications
 */
@Injectable({
  providedIn: 'root',
})
export class ServiceWorkerTrackerService {
  constructor(private swUpdate: SwUpdate, private trackerService: TrackerService) {}

  start(): void {
    if (!this.swUpdate.isEnabled) {
      this.trackerService.event('service_worker', 'disabled');
    } else {
      this.swUpdate.versionUpdates.subscribe((evt) => {
        switch (evt.type) {
          case 'VERSION_DETECTED':
            console.log(`Downloading new app version: ${evt.version.hash}`);
            this.trackerService.event('service_worker', 'version_detected', { version: evt.version.hash });
            break;
          case 'VERSION_READY':
            console.log(`Current app version: ${evt.currentVersion.hash}`);
            console.log(`New app version ready for use: ${evt.latestVersion.hash}`);
            this.trackerService.event('service_worker', 'version_ready', {
              current: evt.currentVersion.hash,
              version: evt.latestVersion.hash,
            });
            break;
          case 'VERSION_INSTALLATION_FAILED':
            console.log(`Failed to install app version '${evt.version.hash}': ${evt.error}`);
            this.trackerService.event('service_worker', 'version_installation_failed', {
              hash: evt.version.hash,
              error: evt.error,
            });
            break;
        }
      });
    }
  }
}
