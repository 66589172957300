import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { SlackErrorRequest } from './slack.model';

/**
 * Service to log errors in slack.
 */
@Injectable({
  providedIn: 'root',
})
export class SlackService {
  constructor(private http: HttpClient) {}

  /**
   * Log a message in slack.
   *
   * @param text the message to be logged
   */
  sendMessage(text: string): void {
    this.http
      .post<void>(`${environment.apiUrl}/slack/send`, {
        text,
      })
      .subscribe();
  }

  /**
   * Send an error to be logged in slack.
   *
   * @param data the error data
   */
  sendError(data: SlackErrorRequest): void {
    this.http.post<void>(`${environment.apiUrl}/slack/send-error`, data).subscribe();
  }

  /**
   * Check if a given URL corresponds to the slack controller.
   *
   * @param url the url to check
   * @returns true, if the url matches
   */
  matches(url: string): boolean {
    return url?.startsWith(`${environment.apiUrl}/slack`);
  }
}
