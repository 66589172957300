import { Platform } from '@angular/cdk/platform';
import { HttpErrorResponse } from '@angular/common/http';
import { ErrorHandler, Injectable } from '@angular/core';
import { SlackService } from '../../business/slack/slack.service';
import { TrackerService } from '../../business/tracker/services/tracker.service';

/**
 * Error handler for any error thrown in the app.
 */
@Injectable()
export class GlobalErrorHandler extends ErrorHandler {
  /*
   * The stack trace of the last error reported.
   */
  private lastStackTrace?: string;

  /*
   * The time when the last error was reported.
   */
  private lastTimestamp: number = 0;

  /*
   * The minimum interval for the same error to be reported again.
   */
  private minInterval: number = 30 * 1000; // 30 seconds

  constructor(private slackService: SlackService, private trackerService: TrackerService, public platform: Platform) {
    super();
  }

  /**
   * Handles captured errors. Logs it in slack.
   *
   * @param error the error thrown
   */
  handleError(error: any): void {
    // Ignore http errors since they're handled in the interceptor
    if (!(error instanceof HttpErrorResponse)) {
      let stack;
      if (error.stack) {
        // For chrome browsers, the stack property already includes the error name and message
        stack = this.platform.BLINK ? error.stack : `${error.name}: ${error.message}\n${error.stack}`;
      } else {
        stack = `${error}`;
      }

      this.reportError(stack);
    }

    super.handleError(error);
  }

  /**
   * Report a client error to Slack.
   *
   * @param stackTrace the error stack
   */
  reportError(stackTrace: string): void {
    const timestamp = new Date().getTime();

    if (stackTrace !== this.lastStackTrace || timestamp - this.lastTimestamp > this.minInterval) {
      const data = {
        title: 'ARMS Javascript error reported.',
        stackTrace,
        fields: [
          {
            title: 'Location',
            value: location.href,
          },
          {
            title: 'Recent activity',
            value: this.trackerService.history.join('\n'),
          },
        ],
      };

      this.slackService.sendError(data);
    }

    this.lastStackTrace = stackTrace;
    this.lastTimestamp = timestamp;
  }
}
