import { CommonModule as AngularCommonModule, CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { RouterModule } from '@angular/router';
import { FrameworkModule } from 'app/modules/common/framework/framework.module';
import { MaterialModule } from 'app/modules/common/material-module';
import { LoginWithPasswordComponent } from './components/login-with-password/login-with-password.component';
import { LoginComponent } from './components/login.component';
import { JwtExpiredInterceptor } from './interceptors/jwt-expired.interceptor';
import { JwtInterceptor } from './interceptors/jwt.interceptor';
import { UnauthorizedInterceptor } from './interceptors/unauthorized.interceptor';

@NgModule({
  declarations: [LoginComponent, LoginWithPasswordComponent],
  providers: [
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtExpiredInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: JwtInterceptor,
      multi: true,
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: UnauthorizedInterceptor,
      multi: true,
    },
  ],
  imports: [CommonModule, AngularCommonModule, FrameworkModule, MaterialModule, FormsModule, ReactiveFormsModule, RouterModule],
  exports: [LoginComponent],
})
export class LoginModule {}
