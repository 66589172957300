import { KeyValue } from '@angular/common';
import { ChangeDetectionStrategy, Component, computed, effect, input, signal } from '@angular/core';
import { PortfolioPerformanceTO } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { PortfolioService } from 'app/modules/common/business/portfolio/services/portfolio.service';
import { trackLoading } from 'app/modules/common/framework/utils/observable-utils';
import { groupBy } from 'lodash-es';
import { DateTime } from 'luxon';

/**
 * Component for the list of portfolios with performance data.
 */
@Component({
  selector: 'app-portfolio-performance',
  templateUrl: './portfolio-performance.component.html',
  styleUrl: './portfolio-performance.component.scss',
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class PortfolioPerformanceComponent {
  /**
   * Input date to use as reference.
   */
  asOf = input.required<DateTime>();

  /**
   * Effect to trigger search on date changes.
   */
  private asOfEffect = effect(
    () => {
      if (this.asOf()) {
        this.loadData();
      }
    },
    { allowSignalWrites: true },
  );

  /**
   * Whether loading data from the server.
   */
  loading = signal(true);

  /**
   * Whether there are estimated values.
   */
  isEstimated = computed(() => {
    return this.data().some((a) => a.aumEstimated);
  });

  /**
   * The list of portfolios with performance data.
   */
  data = signal<PortfolioPerformanceTO[]>([]);

  /**
   * The list of portfolios with performance data grouped by product line.
   */
  groupedData = computed(() => {
    return groupBy(this.data(), (d) => d.productLine);
  });

  /**
   * The total AUM for all portfolios.
   */
  totalAum = computed(() => {
    return this.data().reduce((total, p) => p.aum + total, 0);
  });

  constructor(private portfolioService: PortfolioService) {}

  /**
   * Load the data from the server.
   */
  private loadData() {
    this.portfolioService
      .getPerformanceData(this.asOf())
      .pipe(trackLoading(this.loading))
      .subscribe((data) => {
        this.data.set(data);
      });
  }

  /**
   * Sort the portfolio groups by the product line order attribute.
   */
  sortGroups(a: KeyValue<string, PortfolioPerformanceTO[]>, b: KeyValue<string, PortfolioPerformanceTO[]>): number {
    // Notice the key will always be a string, so we must compare to string 'null'
    if (a.key === 'null') {
      return 1;
    }

    if (b.key === 'null') {
      return -1;
    }

    return a.value[0].productLineOrder! - b.value[0].productLineOrder!;
  }
}
