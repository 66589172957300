<div class="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center">
  <mat-card appearance="outlined" class="mat-elevation-z8">
    <mat-card-header>
      <h3>Log into ARMS</h3>
    </mat-card-header>
    <mat-card-content>
      <form (ngSubmit)="login()" #loginForm="ngForm" class="tw-flex tw-flex-col">
        <mat-form-field>
          <mat-label>Email</mat-label>
          <input matInput [(ngModel)]="model.username" name="username" />
        </mat-form-field>

        <mat-form-field>
          <mat-label>Password</mat-label>
          <input type="password" matInput [(ngModel)]="model.password" name="password" />
        </mat-form-field>

        @if (loginError) {
          <div>Login failed. Please try again.</div>
        }

        <button type="submit" mat-raised-button color="primary" [disabled]="busy">Login</button>

        <button type="button" class="abs-margin-top" mat-raised-button color="secondary" (click)="loginAD()">Login Innovative</button>
      </form>
    </mat-card-content>
  </mat-card>
</div>
