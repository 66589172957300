<div class="tw-flex tw-flex-1 tw-flex-col tw-items-center tw-justify-center">
  <mat-card class="mat-elevation-z8">
    <mat-card-header>
      <h3>Log into ARMS</h3>
    </mat-card-header>
    <mat-card-content>
      <div class="tw-flex tw-flex-col">
        @if (loginError) {
          <div class="abs-margin-vertical">Login failed. Please try again.</div>
        }

        @if (busy) {
          <div class="abs-margin-vertical">
            Please wait for the authentication process to complete. You may be redirected to the authentication provider.
          </div>
        } @else {
          <button class="abs-margin-top" mat-raised-button color="secondary" (click)="loginAD()">Start</button>
        }
      </div>
    </mat-card-content>
  </mat-card>
</div>
