import { Component } from '@angular/core';

/**
 * Component that will only serve to simulate fake navigation.
 */
@Component({
  selector: 'app-fake-route',
  standalone: true,
  imports: [],
  template: '',
  styles: '',
})
export class FakeRouteComponent {}
