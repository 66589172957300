import { AfterViewInit, Component, ElementRef, HostBinding, OnDestroy, signal, ViewChild } from '@angular/core';
import { FormControl } from '@angular/forms';
import { MAT_DATE_FORMATS } from '@angular/material/core';
import { MatDatepicker } from '@angular/material/datepicker';
import { ClientAllocation } from 'app/modules/common/business/client/model/client-allocation.model';
import { ConsultantAllocation } from 'app/modules/common/business/client/model/consultant-allocation.model';
import { OpportunitiesByConsultantTO, OpportunityViewTO } from 'app/modules/common/business/opportunity/model/opportunity.model';
import { PortfolioAllocation } from 'app/modules/common/business/portfolio/model/portfolio-allocation.model';
import { TrackerService } from 'app/modules/common/business/tracker/services/tracker.service';
import { MONTH_YEAR_DATE_FORMATS } from 'app/modules/common/framework/date-format/custom-date-format';
import { DateTime } from 'luxon';

/**
 * Home page component.
 */
@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
  providers: [{ provide: MAT_DATE_FORMATS, useValue: MONTH_YEAR_DATE_FORMATS }],
})
export class HomeComponent implements AfterViewInit, OnDestroy {
  /**
   * The selected portfolio.
   */
  selectedPortfolio?: PortfolioAllocation;

  /**
   * The selected client.
   */
  selectedClient?: ClientAllocation;

  /**
   * The selected consultant.
   */
  selectedConsultant?: ConsultantAllocation;

  /**
   * The selected opportunity.
   */
  selectedOpportunity?: OpportunityViewTO;

  /**
   * The selected consultant for opportunities.
   */
  selectedOpportunityConsultant?: OpportunitiesByConsultantTO;

  /**
   * The index of the selected tab. Used to programatically change tabs.
   */
  selectedTab = 0;

  /**
   * Apply the container class.
   */
  @HostBinding('class') class = 'tw-@container';

  /**
   * The home container element.
   */
  @ViewChild('homeContainer', { read: ElementRef })
  private homeContainer!: ElementRef;

  /**
   * Resize observer reference, to hide/show the list of emails depending on the component width.
   */
  private observer?: ResizeObserver;

  /**
   * The home width.
   */
  homeWidth = signal<number | null>(null);

  /**
   * The current date to block selecting future dates.
   */
  maxDate = DateTime.now().endOf('month');

  /**
   * Form control for the date filter input.
   */
  dateControl = new FormControl<DateTime>(DateTime.now().minus({ days: 7 }).endOf('month'), { nonNullable: true });

  constructor(private trackerService: TrackerService) {}

  /**
   * Angular lifecycle hook called after view is initialized.
   * Configures resize observer.
   */
  ngAfterViewInit() {
    this.observer = new ResizeObserver((entries) => {
      for (const entry of entries) {
        this.homeWidth.set(entry.contentRect.width);
      }
    });

    this.observer.observe(this.homeContainer.nativeElement);
  }

  /**
   * Angular lifecycle hook called as the component is destroyed.
   * Disconnects the resize observer.
   */
  ngOnDestroy(): void {
    if (this.observer) {
      this.observer.disconnect();
    }
  }

  /**
   * Portfolio selection event.
   * @param portfolio the selected portfolio.
   */
  selectPortfolio(portfolio?: PortfolioAllocation): void {
    this.selectedPortfolio = portfolio;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedPortfolio) {
      this.selectedTab = 1;
    } else {
      this.selectedTab = 0;
    }

    this.trackFilter();
  }

  /**
   * Client selection event.
   * @param client the selected client
   */
  selectClient(client?: ClientAllocation): void {
    this.selectedClient = client;
    this.selectedConsultant = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedClient) {
      this.selectedTab = 3;
    } else {
      this.selectedTab = 1;
    }

    this.trackFilter();
  }

  /**
   * Consultant selection event.
   *
   * @param consultant the selected consultant
   */
  selectConsultant(consultant?: ConsultantAllocation): void {
    this.selectedConsultant = consultant;
    this.selectedClient = undefined;
    this.selectedOpportunity = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedConsultant) {
      this.selectedTab = 3;
    } else {
      this.selectedTab = 1;
    }

    this.trackFilter();
  }

  /**
   * Handle selecting an opportunity.
   *
   * @param opportunity the selected opportunity
   */
  selectOpportunity(opportunity?: OpportunityViewTO): void {
    this.selectedOpportunity = opportunity;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunityConsultant = undefined;

    if (this.selectedOpportunity) {
      this.selectedTab = 3;
    } else {
      this.selectedTab = 2;
    }

    this.trackFilter();
  }

  /**
   * Handle selecting a consultant for an opportunity .
   *
   * @param opportunity the selected opportunity
   */
  selectOpportunityConsultant(opportunity?: OpportunitiesByConsultantTO): void {
    this.selectedOpportunity = undefined;
    this.selectedClient = undefined;
    this.selectedConsultant = undefined;
    this.selectedOpportunityConsultant = opportunity;

    if (this.selectedOpportunityConsultant) {
      this.selectedTab = 3;
    } else {
      this.selectedTab = 2;
    }

    this.trackFilter();
  }

  /**
   * Track changes in the selected filters.
   */
  private trackFilter(): void {
    this.trackerService.event('home', 'filter', {
      portfolio: this.selectedPortfolio?.ticker,
      client: this.selectedClient?.name,
      consultant: this.selectedConsultant?.name,
      opportunity: this.selectedOpportunity?.idtOpportunity,
      opportunityConsultant: this.selectedOpportunityConsultant?.name,
    });
  }

  /**
   * Sets the proper date value when the month is selected, not requiring the day to be selected.
   *
   * @param fullDate the full date tha comes when the client choose a month on calendar
   * @param datepicker responsible for managing the datepicker popup/dialog
   */
  monthSelected(fullDate: DateTime, datepicker: MatDatepicker<DateTime>): void {
    this.dateControl.setValue(fullDate.endOf('month'));

    datepicker.close();
  }
}
