<div [ngClass]="{ expanded: showIndex() }">
  @if (portfolio(); as portfolio) {
    <div class="abs-clickable hoverable line tw-flex tw-px-1" (click)="toggleShowIndex()">
      <div class="tw-flex tw-w-[18px] tw-items-center" matRipple>
        @if (portfolio.idtIndex && portfolio.asOf) {
          <mat-icon [fontIcon]="showIndex() ? 'mdi-chevron-up' : 'mdi-chevron-down'" />
        }
      </div>
      <div class="tw-w-[50px]">{{ portfolio.asOf | date: 'MM/dd' }}</div>
      <div class="tw-flex-1 tw-overflow-hidden tw-font-medium">
        <div class="tw-flex">
          <app-portfolio-context-menu [id]="portfolio.idtPortfolio" [displayText]="portfolio.ticker" class="abs-text-overflow-ellipsis" />
        </div>
      </div>
      <div class="tw-w-[60px] tw-text-right">
        @if (portfolio.missingReturns) {
          <mat-icon fontIcon="mdi-alert-outline" class="abs-color-warn" matTooltip="Missing returns in ABSolute" />
        } @else {
          <span>{{ portfolio.aum | millions | number: '1.1-1' }}M</span>
          <span [ngClass]="{ 'abs-hidden': !portfolio.aumEstimated }">*</span>
        }
      </div>
      <div class="tw-w-[50px] tw-text-right">
        <app-performance-value [value]="portfolio.mtd" [estimated]="portfolio.mtdEstimated" [showType]="false" />
      </div>
      <div class="tw-w-[50px] tw-text-right">
        <app-performance-value [value]="portfolio.qtd" [estimated]="portfolio.mtdEstimated" [showType]="false" />
      </div>
      <div class="tw-w-[50px] tw-text-right">
        <app-performance-value [value]="portfolio.ytd" [estimated]="portfolio.mtdEstimated" [showType]="false" />
      </div>
    </div>

    @if (showIndex()) {
      @if (indexData(); as indexData) {
        <div class="line tw-flex tw-px-1">
          <div class="tw-w-[18px]"></div>
          <div class="tw-w-[50px]"></div>

          <div class="abs-text-overflow-ellipsis blue-data tw-flex-1">{{ indexData.name }}</div>
          <div class="tw-w-[50px] tw-text-right">
            <span class="blue-data">{{ indexData.mtd | number: '0.2-2' | emptyValue: '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[50px] tw-text-right">
            <span class="blue-data">{{ indexData.qtd | number: '0.2-2' | emptyValue: '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[50px] tw-text-right">
            <span class="blue-data">{{ indexData.ytd | number: '0.2-2' | emptyValue: '-' }}</span>
            <span class="abs-hidden">*</span>
          </div>
        </div>
        <div class="line tw-mb-1 tw-flex tw-px-1">
          <div class="tw-w-[18px]"></div>
          <div class="tw-w-[50px]"></div>

          <div class="tw-flex-1">Excess</div>
          <div class="tw-w-[50px]"></div>
          <div class="tw-w-[50px] tw-text-right">
            @if (portfolio.mtd != null && indexData.mtd != null) {
              <span>{{ portfolio.mtd - indexData.mtd | number: '0.2-2' | emptyValue: '-' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[50px] tw-text-right">
            @if (portfolio.qtd != null && indexData.qtd != null) {
              <span>{{ portfolio.qtd - indexData.qtd | number: '0.2-2' | emptyValue: '-' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
          <div class="tw-w-[50px] tw-text-right">
            @if (portfolio.ytd != null && indexData.ytd != null) {
              <span>{{ portfolio.ytd - indexData.ytd | number: '0.2-2' | emptyValue: '-' }}</span>
            } @else {
              <span>-</span>
            }
            <span class="abs-hidden">*</span>
          </div>
        </div>
      }
    }
  }
</div>
