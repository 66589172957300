import { Component, DestroyRef, OnInit } from '@angular/core';
import { takeUntilDestroyed } from '@angular/core/rxjs-interop';
import { MatSnackBar, MatSnackBarRef, TextOnlySnackBar } from '@angular/material/snack-bar';
import { Title } from '@angular/platform-browser';
import { ActivationEnd, Router } from '@angular/router';
import { fromEvent } from 'rxjs';
import { filter, tap } from 'rxjs/operators';
import { AuthService } from './modules/common/business/auth/services/auth.service';
import { SearchService } from './modules/common/business/search/services/search.service';
import { TrackerService } from './modules/common/business/tracker/services/tracker.service';
import { ApplicationUser } from './modules/routes/login/model/application-user';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent implements OnInit {
  activeSearch: boolean = false;

  snackBarRef?: MatSnackBarRef<TextOnlySnackBar>;

  constructor(
    private searchService: SearchService,
    private router: Router,
    private titleService: Title,
    private trackerService: TrackerService,
    private authService: AuthService,
    private snackBar: MatSnackBar,
    private destroyRef: DestroyRef,
  ) {}

  ngOnInit() {
    // Sync search active flag
    this.searchService.active$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((active) => (this.activeSearch = active)),
      )
      .subscribe();

    // Setup tracking for the current user
    this.authService.user$
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        tap((user: ApplicationUser | null) => {
          this.trackerService.identify(user);
        }),
      )
      .subscribe();

    // Listen to changes on the primary outlet routing
    this.router.events
      .pipe(
        takeUntilDestroyed(this.destroyRef),
        filter((e) => e instanceof ActivationEnd && e.snapshot.outlet === 'primary' && !!e.snapshot.component),
        tap((e) => {
          const routeTitle = (e as ActivationEnd).snapshot.data.title;

          // Set the page title based on the route data
          let title = 'ARMS';
          if (routeTitle) {
            title += `-${routeTitle}`;
          }
          this.titleService.setTitle(title);

          // Track page view
          this.trackerService.event('page', 'view', {
            page_title: title,
          });
        }),
      )
      .subscribe();

    // If network connection drops, show snackbar alert
    fromEvent(window, 'offline')
      .pipe(
        tap(() => {
          this.snackBarRef = this.snackBar.open(
            "Connection refused: please make sure you're connected to the network, or contact the system administrator.",
          );
        }),
      )
      .subscribe();

    // If network is restablished, dismiss the alert
    fromEvent(window, 'online')
      .pipe(
        tap(() => {
          this.snackBarRef?.dismiss();
        }),
      )
      .subscribe();
  }
}
